<nz-table #expandTable [nzData]="tableData.length ? tableData : ['']" [nzScroll]="{ x: '900px', y: '370px'}"
  [nzOuterBordered]="true" class="pivot-table" [nzBordered]="true" [nzFrontPagination]="false" [nzShowPagination]="true"
  [nzPaginationType]="'default'" [nzTableLayout]="'fixed'" [nzLoading]="tableConfig.isLoading" nzSize="small"
  [nzShowTotal]="tableConfig.tableLevel == 1 ? tableTotal : false" [nzPageSize]="tableConfig.limit"
  [nzTotal]="tableConfig.tableTotalCount" (nzPageIndexChange)="onPagechange($event)">
  <thead>
    <tr>
      <!-- <th *ngIf="tableConfig.hasSubLevels" nzWidth="20px"></th> -->
      <th *ngFor="let column of tableConfig.tableColumns" [nzAlign]="'left'" [nzWidth]="'200px'">
        {{ column.label }}
      </th>
    </tr>
  </thead>
  <tbody *ngIf="tableData.length">
    <ng-container *ngFor="let row of tableData; let i = index; let last = last">
      <tr [class]="'level-' + row.level" [style]="{'text-align': 'left'}">
        <!-- <td *ngIf="tableConfig.hasSubLevels && row.expand" class="expand-icon" [nzShowExpand]="row.expand"
          [(nzExpand)]="row.expanded" (nzExpandChange)="onExpandTable($event, row, i)">
        </td> <td *ngIf="tableConfig.hasSubLevels && !row.expand">
        </td> -->
        <ng-container *ngFor="let header of tableConfig.tableColumns; let f = first; let l = last">
          <td *ngIf="f"
            [style]="{'padding-left': row.level == 1 ? '0.25rem' : row.level == tableConfig.maxLevel ? (row.level)+ 'rem' : (row.level - 1) + 'rem', 'padding-right': l ? row.level == tableConfig.maxLevel ? '0.25rem' : (tableConfig.maxLevel - row.level) + 'rem' : '0'}"
            class="table-cell">
            <div [style]="{'display': 'inline-flex', 'vertical-align': 'middle', 'margin-right': '0.3rem'}"><span
                nz-icon nzType="plus" nzTheme="outline" [style.color]="'black'"
                *ngIf="tableConfig.hasSubLevels && row.expand && !row.expanded"
                (click)="onExpandTable($event, row, i); row.expanded = true"></span>
              <span nz-icon nzType="minus" nzTheme="outline" [style.color]="'black'"
                *ngIf="tableConfig.hasSubLevels && row.expand && row.expanded"
                (click)="onCollapseTable($event, row, i); row.expanded = false"></span>
            </div>
            <div [style]="{'display': 'inline-flex', 'vertical-align': 'middle'}"
              title="{{ row.dataColumns[header.value] }}">
              {{ row.dataColumns[header.value] }}</div>
          </td>
          <td *ngIf="!f"
            [style]="{'padding-left': '8px', 'padding-right': l ? row.level == tableConfig.maxLevel ? '0.25rem' : (tableConfig.maxLevel - row.level)+ 'rem' : 0}"
            class="table-cell" title="{{ row.dataColumns[header.value] }}">
            {{ row.dataColumns[header.value] }}</td>
        </ng-container>
      </tr>
      <tr *ngIf="row.subLevelData" [nzExpand]="row.expanded" class="table-row-panel">
        <app-config-table [tableData]="row.subLevelData" [tableConfig]="row.subLevelTableConfig"
          [parentLevelData]="row"></app-config-table>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<ng-template #tableTotal let-range="range" let-total>
  Showing {{ range[0].toLocaleString() }} -
  {{ range[1].toLocaleString() }} of {{ total.toLocaleString() }} Records
</ng-template>