import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  LanguageConstants,
  NavBarActiveTabMapper,
  NavigationTabs,
  bottomNavigationList,
} from './shared/constants';
import {
  app_labels,
  common_labels,
} from './shared/constants/ui_labels_translation_mapping';

import { AppConfig } from './app.config';
import { AppService } from './app.service';
import { DateUtils } from './shared/utils/date-utils';
import Drawer from '../../node_modules/zsui/src/drawer/drawer.m.js';
import { EnvironmentConfig } from './shared/models/environment';
import { IdmDetailsService } from './idm-details.service';
import { LocalStorageService } from './local-storage.service';
import { MultiSelectDropDown } from './shared/models/multiSelectDropDown';
import { MultiSelectDropdownComponent } from './revo-core/multi-select-dropdown/multi-select-dropdown.component';
import { RevoCoreService } from './revo-core/revo-core.service';
import { Subscription } from 'rxjs';
import Tooltip from './revo-core/zsui-component-extensions/tooltip.m.js';
import { TranslateService } from '@ngx-translate/core';
import Utils from './app.utils';
import { homePagePath } from './constants';
import { AppRoles } from './constants';

Tooltip.register();
Drawer.register('zs-drawer');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  bottomNavigationList = bottomNavigationList;

  environment: EnvironmentConfig;
  @ViewChild('multiLanguage', { static: true })
  multiLanguage: MultiSelectDropdownComponent;
  @ViewChild('evironmentSelectionList', { static: true })
  evironmentSelectionList: MultiSelectDropdownComponent;
  userBarHtml: SafeHtml;
  isChangeEnvPopUpOpen = false;
  isEnvironmentDetailsLoading = false;
  selectedEnvironmentIdRadio: string;
  selectedEnvironmentNameRadio: string;
  savedEnvironmentId: string;
  savedEnvironmentName: string;
  environmentList = [];
  subscription: Subscription;
  brmServiceSubscription: Subscription;
  isAllowNavigation = true;
  isUpdateDataPopupShow = false;
  changeFunctionCall: Function;
  homePagePath = homePagePath;
  user: any;
  showNotifyUnsupportedBrowserPopup = false;
  selectedLanguage: any;
  selectedEnvironment: string;
  selectedTab: any;

  ui_labels = app_labels;
  common_labels = common_labels;
  language = {};
  technical_support_label: string;
  environment_change_label: string;
  discard_changes_label: string;
  unsupported_browser_label: string;
  environment_change_warning_label: string;
  copyright_label: string;
  icpLabel: string;
  cyberSecurityLabel: string;
  english: string;
  spanish: string;
  german: string;
  japanese: string;
  selectLanguageLabel: string;
  userLanguage: string;
  multiLanguageList = [];
  isFirstChange = true;
  navigationTabs = [];
  navigationTabsInDropdown = [];
  navigationTabsCount: number;
  selectedTabInDropdown = '';
  isRoutingRequired = true;
  showTabInDropdown = true;
  isNavEllipsisActive = false;
  showNavDropDown = false;
  navigationTabsDetails: any = [];
  navigationTabsInDataTab: any = [];
  heap: any;
  selectedTabInDropDownIcon = '';
  appliactionLogo: string;
  applicationName: string;
  favIcon;
  @ViewChild('navigationTabsSelect')
  navigationSelect: MultiSelectDropdownComponent;
  @ViewChild('dropdownElementRef', { static: true })
  dropdownElementRef: ElementRef;
  @ViewChild('dataDrawerRef', { static: true }) dataDrawerRef: ElementRef;
  showDataTabDrawerToggle = false;
  selectEnvironmentLabel: string;
  environmentDropdownList = [];
  isTouched = false;
  openHelpPopup = false;
  dataTabClicked = false;
  helpCenterLabel: string;
  showErrorMessageOnHelpPopup: boolean;
  errorMessageOnHelpPopup: string;

  userGuideLabel: string;
  activeTab: string;
  navBarActiveTabMapper = NavBarActiveTabMapper;
  callingEnvPopup = false;

  constructor(
    private router: Router,
    private appService: AppService,
    private _revoCoreService: RevoCoreService,
    private _sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    private _idmDetailsService: IdmDetailsService,
    private _translate: TranslateService,
    private config: AppConfig,
    private dateUtils: DateUtils
  ) {
    this.environment = this.config.getConfigObject();
    const translated_labels = this._translate.get([
      this.ui_labels.TECHNICAL_SUPPORT,
      this.ui_labels.ENVIRONMENT_CHANGE_TITLE,
      this.ui_labels.UNSAVED_CHANGES_TITLE,
      this.ui_labels.UNSUPPORTED_BROWSER_TITLE,
      this.ui_labels.ENVIRONMENT_CHANGE_WARNING,
      this.ui_labels.ENGLISH,
      this.ui_labels.ENGLISH_US,
      this.ui_labels.SPANISH,
      this.ui_labels.SPANISH_LATIN_AMERICA,
      this.ui_labels.GERMAN,
      this.ui_labels.GERMAN_DE,
      this.ui_labels.JAPANESE,
      this.ui_labels.SELECT_LANGUAGE,
      this.ui_labels.CHINESE_SIMPLIFIED,
      this.ui_labels.PORTUGUESE_BRAZIL,
      this.ui_labels.ICP_NO,
      this.ui_labels.CYBERSECURITY_NO,
      this.ui_labels.DATA_MENU,
      this.ui_labels.SELECT_ENVIRONMENT,
      this.ui_labels.HELP_CENTER_TITLE,
      this.ui_labels.USER_GUIDE_ERROR,
    ]);

    const $this = this;
    const myObserver = {
      next: function (translated_labels) {
        $this.language = LanguageConstants.LANGUAGE_MAPPINGS;
        $this.technical_support_label =
          translated_labels[$this.ui_labels.TECHNICAL_SUPPORT];
        $this.environment_change_label =
          translated_labels[$this.ui_labels.ENVIRONMENT_CHANGE_TITLE];
        $this.discard_changes_label =
          translated_labels[$this.ui_labels.UNSAVED_CHANGES_TITLE];
        $this.unsupported_browser_label =
          translated_labels[$this.ui_labels.UNSUPPORTED_BROWSER_TITLE];
        $this.environment_change_warning_label =
          translated_labels[$this.ui_labels.ENVIRONMENT_CHANGE_WARNING];
        $this.selectEnvironmentLabel =
          translated_labels[$this.ui_labels.SELECT_ENVIRONMENT];
        $this.helpCenterLabel =
          translated_labels[$this.ui_labels.HELP_CENTER_TITLE];
        $this.userGuideLabel =
          translated_labels[$this.ui_labels.USER_GUIDE_ERROR];
        $this.copyright_label = $this._translate.instant(
          $this.ui_labels.COPYRIGHT,
          {
            year: $this.dateUtils.getCurrentDate().getFullYear().toString(),
          }
        );
        if ($this.environment.footer !== null) {
          if ($this.environment.footer.icp !== null) {
            if (!$this.environment.isChinaInstance) {
              $this.icpLabel = $this._translate.instant(
                $this.ui_labels.ICP_NO,
                {
                  icpNo: $this.environment.footer.icp.number,
                  icpNoSuffix: $this.environment.footer.icp.suffix,
                }
              );
            } else {
              $this.icpLabel = `沪ICP备${$this.environment.footer.icp.number}号${$this.environment.footer.icp.suffix}`;
            }
          }
          if ($this.environment.footer.cyberSecurity !== null) {
            if (!$this.environment.isChinaInstance) {
              $this.cyberSecurityLabel = $this._translate.instant(
                $this.ui_labels.CYBERSECURITY_NO,
                {
                  cyberSecurityNo:
                    $this.environment.footer.cyberSecurity.number,
                }
              );
            } else {
              $this.cyberSecurityLabel = `沪公网安备 ${$this.environment.footer.cyberSecurity.number}号`;
            }
          }
        }
        $this.selectLanguageLabel =
          translated_labels[$this.ui_labels.SELECT_LANGUAGE];
        const language_items = [];

        for (const language of $this.environment.supportedLanguage) {
          const selectOption = new MultiSelectDropDown();
          selectOption.isSelected = $this._translate.currentLang === language;
          selectOption.displayLabel =
            translated_labels[$this.ui_labels[$this.language[language]]];
          selectOption.value = language;
          language_items.push(selectOption);
        }
        $this.multiLanguageList = language_items;
      },
      error: (err) => console.error('Observer got an error: ' + err),
      complete: () => console.log('Observer got a complete notification'),
    };
    translated_labels.subscribe(myObserver);
  }

  userRole;
  routeLink;

  ngOnInit() {
    if (!Utils.detectChrome()) {
      this.showNotifyUnsupportedBrowserPopup = true;
    }
    this.get_user_session();
    this._idmDetailsService.role_details.subscribe((role_details) => {
      this.user = role_details;
    });

    const environmentDetails =
      this._localStorageService.getSelectedEnvironmentDetails();
    this.savedEnvironmentId = environmentDetails[0];
    this.savedEnvironmentName = environmentDetails[1];

    this.appliactionLogo = this.environment.application.logoname;
    this.applicationName = this.environment.application.applicationName;
    this.favIcon = this.environment.application.favicon;

    // addition of heap
    // if (this.environment.heapAppId) {
    //   this.loadHeapScript(this.environment.heapAppId);
    // }
    document.body.style.overflow = 'hidden';

    this.routeLink = window.location.href;
    this.onRouteChange();
  }

  ngOnDestroy() {
    this._localStorageService.clearLocalStorage();
  }

  changeLanguage(language) {
    this._localStorageService.setUserLanguageDetails(
      JSON.stringify(language[0].value)
    );
    if (!this.isFirstChange) {
      window.location.reload();
    } else {
      this.isFirstChange = false;
    }
  }

  isNotifyUnsupportedBrowserCancel() {
    this.showNotifyUnsupportedBrowserPopup = false;
  }

  get_user_session(): void {
    this._revoCoreService.get_user_session().subscribe(
      (result) => {
        this.userBarHtml = this._sanitizer.bypassSecurityTrustHtml(
          result['htmlContent']
        );
        this._idmDetailsService.set_roles(result['roles'], result['username']);
        let allRoles = this._idmDetailsService.get_roles();
        this.userRole = allRoles['SiteAdmin'] ? 'admin' : 'steward';
        this._localStorageService.setData('role', this.userRole);
        if (this.heap) {
          this.heap.identify(result['username']);
          this.heap.addUserProperties({ Role: result['roles'] });
        }
      },
      (exception) => {}
    );
  }

  loadHeapScript(heapAppId: String) {
    const heap: any = [];
    (window['heap'] = window['heap'] || []),
      (heap.load = function (e, t) {
        (window['heap'].appid = e), (window['heap'].config = t = t || {});
        const r = document.createElement('script');
        (r.type = 'text/javascript'),
          (r.async = !0),
          (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
        for (
          let n = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            o = 0;
          o < p.length;
          o++
        ) {
          heap[p[o]] = n(p[o]);
        }
      });
    heap.load(heapAppId);
    this.heap = heap;
  }

  supportingIconsClicked(supportingIconName) {
    this.openHelpPopup =
      supportingIconName === NavigationTabs.HELP ? true : false;
    this.closeZsErrorToasterOnHelpPopup();
  }

  closeZsErrorToasterOnHelpPopup() {
    this.showErrorMessageOnHelpPopup = false;
  }
  onRouteChange() {
    let path;
    this.routeLink = window.location.href;
    path = this.routeLink.split('/');
    if (path.includes('admin')) {
      this.selectedTab = 'admin';
    } else {
      this.selectedTab = 'home1';
    }
  }
}
