import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-zs-flow-action-node',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.less'],
})
export class ActionComponent implements OnInit {
  @Input() nodedata: any;
  progressBarStyle: any;
  constructor() {}

  ngOnInit() {
    this.progressBarStyle = {
      width: this.nodedata.completion + '%',
    };
  }
}
