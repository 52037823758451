import { Injectable } from '@angular/core';
import { LanguageConstants } from './shared/constants';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  get_local_storage_key_prefix() {
    const currentUrl = location.href;
    return currentUrl.split('/').splice(2, 2).join('/');
  }

  getSelectedEnvironmentDetails() {
    const environmentIdLocalStorageKey =
      this.get_local_storage_key_prefix() + '-environmentId';
    const environmentNameLocalStorageKey =
      this.get_local_storage_key_prefix() + '-environmentName';
    const environmentId = '8';
    const environmentName = localStorage.getItem(
      environmentNameLocalStorageKey
    );
    return [environmentId, environmentName];
  }

  setSelectedEnvironmentDetails(environmentId, environmentName) {
    const environmentIdLocalStorageKey =
      this.get_local_storage_key_prefix() + '-environmentId';
    const environmentNameLocalStorageKey =
      this.get_local_storage_key_prefix() + '-environmentName';
    localStorage.setItem(environmentIdLocalStorageKey, environmentId);
    localStorage.setItem(environmentNameLocalStorageKey, environmentName);
  }

  setUserLanguageDetails(language) {
    const userLanguageLocalStorageKey =
      this.get_local_storage_key_prefix() +
      '-' +
      LanguageConstants.SELECTED_LANGUAGE;
    window.localStorage.setItem(userLanguageLocalStorageKey, language);
  }

  getUserLanguageDetails() {
    const userLanguageLocalStorageKey =
      this.get_local_storage_key_prefix() +
      '-' +
      LanguageConstants.SELECTED_LANGUAGE;
    return window.localStorage.getItem(userLanguageLocalStorageKey);
  }

  clearLocalStorageFrUnauthorizedEnvironmentAccess() {
    const environmentIdKey =
      this.get_local_storage_key_prefix() + '-environmentId';
    const environmentNameKey =
      this.get_local_storage_key_prefix() + '-environmentName';
    const selectedlanguageKey =
      this.get_local_storage_key_prefix() +
      '-' +
      LanguageConstants.SELECTED_LANGUAGE;
    if (environmentIdKey in localStorage) {
      localStorage.removeItem(environmentIdKey);
    }
    if (environmentNameKey in localStorage) {
      localStorage.removeItem(environmentNameKey);
    }
    if (selectedlanguageKey in localStorage) {
      localStorage.removeItem(selectedlanguageKey);
    }
  }

  clearLocalStorage() {
    localStorage.removeItem('selectedTable');
  }

  setData(key, value) {
    localStorage.setItem(key, value);
  }

  getData(key) {
    return localStorage.getItem(key);
  }
}
