import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  pivotTableExpand = new Subject();
  pivotTableCollapse = new Subject();
  pivotTablePageChange = new Subject();

  constructor() {}
}
