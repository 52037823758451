import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedDataService } from '../../shared-data.service';

@Component({
  selector: 'app-config-table',
  templateUrl: './config-table.component.html',
  styleUrls: ['./config-table.component.less'],
})
export class ConfigTableComponent implements OnInit {
  @Input() tableData: any;
  @Input() tableConfig: any;
  // @Input() tableTotalCount: any;
  // @Input() tableCurrentOffset: any;
  @Input() parentLevelData: any;

  @Output() expandTable = new EventEmitter<any>();

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    // console.log('row-level', this.tableData, this.tableTotalCount);
    console.log('table conf', this.tableConfig);
  }

  onExpandTable(event: any, rowData: any, index: number) {
    this.sharedDataService.pivotTableExpand.next({
      event,
      rowData,
      index,
      level: rowData.level,
    });
  }

  onCollapseTable(event: any, rowData: any, index: number) {
    this.sharedDataService.pivotTableCollapse.next({
      event,
      level_index: rowData.level_index,
    });
  }

  onPagechange(event: any) {
    console.log('page', event);
    this.sharedDataService.pivotTablePageChange.next({
      page: event,
      levelDataIndex: this.tableData[0].level_index,
      level: this.tableData[0].level,
      parentData: this.parentLevelData,
      tableCurrentOffset: this.tableConfig.tableCurrentOffset,
    });
  }

  onChildExpand(event: any, rowData: any, index: number) {}
}
